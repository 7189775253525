import { FaPaw, FaStethoscope, FaMobileAlt, FaChartLine } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Products() {
  return (
    <div className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold text-center text-dark mb-12">Our Solutions</h2>
        <div className="grid md:grid-cols-2 gap-8">
          {/* ExaPet Card */}
          <div className="bg-white rounded-xl shadow-lg p-8 transition-transform duration-200 hover:-translate-y-2">
            <div className="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mb-6">
              <FaPaw className="text-primary text-2xl" />
            </div>
            <h3 className="text-xl font-semibold mb-4">ExaPet</h3>
            <p className="text-gray-600 mb-6">
              Complete pet health management solution for pet owners. Track vaccinations, medications, and appointments.
            </p>
            <ul className="space-y-2 mb-6">
              {['Health Records', 'Vaccination Tracking', 'Appointment Management', 'Medication Reminders'].map((feature) => (
                <li key={feature} className="flex items-center text-gray-700">
                  <svg className="w-5 h-5 text-primary mr-2" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            <Link 
              to="/exapet" 
              className="inline-block bg-primary text-white px-6 py-3 rounded-lg font-medium hover:bg-primary/90 transition-colors"
            >
              Learn More
            </Link>
          </div>

          {/* ExaVet Card */}
          <div className="bg-white rounded-xl shadow-lg p-8 transition-transform duration-200 hover:-translate-y-2">
            <div className="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mb-6">
              <FaStethoscope className="text-primary text-2xl" />
            </div>
            <h3 className="text-xl font-semibold mb-4">ExaVet</h3>
            <p className="text-gray-600 mb-6">
              Professional veterinary practice management system designed for modern clinics.
            </p>
            <ul className="space-y-2 mb-6">
              {['Patient Management', 'Clinical Records', 'Billing & Invoicing', 'Inventory Management'].map((feature) => (
                <li key={feature} className="flex items-center text-gray-700">
                  <svg className="w-5 h-5 text-primary mr-2" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            <Link 
              to="/exavet" 
              className="inline-block bg-primary text-white px-6 py-3 rounded-lg font-medium hover:bg-primary/90 transition-colors"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 