import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

export default function Menu() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed w-full z-50 transition-all duration-500 ${
      isScrolled ? 'bg-black/90 backdrop-blur-sm shadow-lg py-2' : 'bg-transparent py-6'
    }`}>
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between">
          <Link 
            to="/" 
            className="text-2xl text-white font-bold tracking-wider hover:text-primary transition-all duration-300 transform hover:scale-105"
          >
            EXACATE
          </Link>
          
          <button className="lg:hidden text-white hover:text-primary transition-colors">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>

          <div className="hidden lg:flex items-center space-x-8">
            {[
              { name: 'Anasayfa', path: '/' },
              { 
                name: 'Ürünlerimiz', 
                path: '/products',
                subItems: [
                  { name: 'ExaPet', path: '/exapet', description: 'Pet bakım yönetim sistemi' },
                  { name: 'ExaVet', path: '/exavet', description: 'Veteriner klinik yönetim sistemi' },
                  { name: 'ExaLab', path: '/exalab', description: 'Laboratuvar yönetim sistemi' }
                ]
              },
              { 
                name: 'Araştırmalar', 
                path: '/research',
                subItems: [
                  { name: 'Yapay Zeka', path: '/ai-research', description: 'AI ve ML araştırmaları' },
                  { name: 'Veterinerlik', path: '/vet-research', description: 'Veteriner bilimi araştırmaları' },
                  { name: 'Sağlık Teknolojileri', path: '/health-tech', description: 'Dijital sağlık çözümleri' }
                ]
              },
              { 
                name: 'Hizmetlerimiz', 
                path: '/services',
                subItems: [
                  { name: 'Danışmanlık', path: '/consulting', description: 'Teknoloji danışmanlığı' },
                  { name: 'Yazılım Geliştirme', path: '/software-development', description: 'Özel yazılım çözümleri' },
                  { name: 'Veri Analizi', path: '/data-analysis', description: 'Veri analizi ve raporlama' }
                ]
              },
              { name: 'Hakkımızda', path: '/about' },
              { name: 'İletişim', path: '/contact' }
            ].map((item) => (
              <div key={item.name} className="relative group">
                <div className="flex items-center space-x-1 cursor-pointer">
                  <Link
                    to={item.path}
                    className="text-white hover:text-primary transition-all duration-300 font-medium"
                  >
                    {item.name}
                  </Link>
                  {item.subItems && (
                    <FaChevronDown className="w-3 h-3 text-white group-hover:text-primary transition-transform duration-300 group-hover:rotate-180" />
                  )}
                </div>
                
                {item.subItems && (
                  <div className="hidden group-hover:block absolute left-0 top-full pt-2 w-64">
                    <div className="bg-white/95 backdrop-blur-sm rounded-lg shadow-lg border border-gray-200 overflow-hidden">
                      {item.subItems.map((subItem) => (
                        <Link
                          key={subItem.name}
                          to={subItem.path}
                          className="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-50 transition-all duration-300 hover:pl-6 group/item"
                        >
                          <div className="flex items-center justify-between">
                            <div>
                              <div className="font-semibold text-gray-900">{subItem.name}</div>
                              {subItem.description && (
                                <div className="text-xs text-gray-500 mt-1">{subItem.description}</div>
                              )}
                            </div>
                            <FaChevronRight className="w-3 h-3 text-gray-400 transition-transform duration-300 group-hover/item:translate-x-1" />
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}
