import Menu from '../components/layout/Menu';
import { FaHospital, FaDatabase, FaChartPie, FaUserMd } from 'react-icons/fa';

export default function ExaVet() {
  return (
    <div className="min-h-screen">
      <Menu />
      
      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-gradient-to-r from-dark to-primary">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="text-white">
              <h1 className="text-4xl font-bold mb-6">ExaVet - Veterinary Practice Management</h1>
              <p className="text-xl mb-8">Streamline your veterinary practice with our comprehensive management solution.</p>
              <button className="bg-white text-primary px-8 py-3 rounded-lg font-semibold hover:bg-gray-100">
                Request Demo
              </button>
            </div>
            <div className="hidden md:block">
              <img src="/exavet-dashboard.png" alt="ExaVet Dashboard" className="rounded-lg shadow-xl" />
            </div>
          </div>
        </div>
      </section>

      {/* Features */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Professional Features</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {[
              {
                icon: <FaHospital className="text-4xl text-primary" />,
                title: "Practice Management",
                description: "Complete solution for managing your veterinary practice"
              },
              {
                icon: <FaDatabase className="text-4xl text-primary" />,
                title: "Patient Records",
                description: "Comprehensive digital patient records and history"
              },
              {
                icon: <FaChartPie className="text-4xl text-primary" />,
                title: "Analytics",
                description: "Detailed insights and reporting for your practice"
              },
              {
                icon: <FaUserMd className="text-4xl text-primary" />,
                title: "Staff Management",
                description: "Efficient staff scheduling and task management"
              }
            ].map((feature, index) => (
              <div key={index} className="flex p-6 bg-white rounded-xl shadow-lg">
                <div className="mr-6">{feature.icon}</div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
} 