import { useState, useEffect } from 'react';
import Menu from '../components/layout/Menu';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { 
  FaMobileAlt, FaBell, FaCalendarAlt, FaChartLine, 
  FaApple, FaGooglePlay 
} from 'react-icons/fa';

export default function ExaPet() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const features = [
    {
      icon: <FaMobileAlt className="text-4xl text-primary" />,
      title: "Kolay Kullanım",
      description: "Sezgisel arayüz ile her yaştan kullanıcı için tasarlandı"
    },
    {
      icon: <FaBell className="text-4xl text-primary" />,
      title: "Akıllı Hatırlatmalar",
      description: "Aşı ve ilaç takibini asla kaçırmayın"
    },
    {
      icon: <FaCalendarAlt className="text-4xl text-primary" />,
      title: "Veteriner Randevuları",
      description: "Tek tıkla randevu oluşturun ve yönetin"
    },
    {
      icon: <FaChartLine className="text-4xl text-primary" />,
      title: "Sağlık Takibi",
      description: "Evcil hayvanınızın sağlık metriklerini izleyin"
    }
  ];

  const screenshots = [
    {
      image: "/exapet-screenshot-1.png",
      title: "Kolay Randevu Yönetimi",
      description: "Veteriner randevularınızı tek tıkla oluşturun ve takip edin",
      features: ["Takvim görünümü", "Anında bildirimler", "Kolay değişiklik", "Geçmiş takibi"]
    },
    {
      image: "/exapet-screenshot-2.png",
      title: "Aşı Takip Sistemi",
      description: "Evcil hayvanınızın aşı takvimini kolayca yönetin",
      features: ["Otomatik hatırlatmalar", "Aşı geçmişi", "Takvim entegrasyonu", "Veteriner önerileri"]
    },
    {
      image: "/exapet-screenshot-3.png",
      title: "Sağlık Geçmişi",
      description: "Tüm sağlık kayıtlarına anında erişin",
      features: ["Detaylı raporlar", "İlaç geçmişi", "Laboratuvar sonuçları", "Tedavi planları"]
    }
  ];

  return (
    <div className="min-h-screen">
      <Menu />
      
      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-gradient-to-r from-primary to-blue-600">
        <div className="container mx-auto px-4">
          <div className={`grid md:grid-cols-2 gap-12 items-center transition-all duration-1000 ${
            isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}>
            <div className="text-white">
              <h1 className="text-4xl font-bold mb-6">ExaPet - Akıllı Evcil Hayvan Bakımı</h1>
              <p className="text-xl mb-8">Evcil hayvanınızın sağlığını, randevularını ve ilaçlarını tek yerden yönetin.</p>
              <div className="flex space-x-4">
                <button className="bg-white text-primary px-6 py-3 rounded-lg font-semibold hover:bg-gray-100">
                  Uygulamayı İndir
                </button>
                <button className="border-2 border-white px-6 py-3 rounded-lg text-white font-semibold hover:bg-white hover:text-primary">
                  Daha Fazla Bilgi
                </button>
              </div>
            </div>
            <div className="hidden md:block">
              <img src="/exapet-app.png" alt="ExaPet App" className="rounded-lg shadow-xl" />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Özellikler</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="text-center p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                <div className="flex justify-center mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* App Screenshots Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-4">Uygulama Özellikleri</h2>
          <p className="text-gray-600 text-center max-w-2xl mx-auto mb-12">
            Modern ve kullanıcı dostu arayüzümüzle tanışın
          </p>

          <div className="max-w-6xl mx-auto">
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={500}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              loop={true}
              className="mySwiper"
            >
              {screenshots.map((screen, index) => (
                <SwiperSlide key={index}>
                  <div className="grid md:grid-cols-2 gap-12 items-center px-4">
                    {/* Sol taraf - Yazılar */}
                    <div>
                      <h3 className="text-3xl font-bold text-gray-900">
                        {screen.title}
                      </h3>
                      <p className="text-gray-600 text-lg mt-4">
                        {screen.description}
                      </p>
                      <ul className="space-y-3 mt-6">
                        {screen.features.map((feature, idx) => (
                          <li key={idx} className="flex items-center text-gray-700">
                            <div className="w-2 h-2 bg-primary rounded-full mr-3"></div>
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Sağ taraf - Telefon */}
                    <div>
                      <div className="relative w-[280px] h-[580px] mx-auto">
                        <img 
                          src="/phone-frame.png" 
                          alt="Phone Frame" 
                          className="absolute inset-0 w-full h-full z-10"
                        />
                        <div className="absolute inset-[10px] rounded-[32px] overflow-hidden">
                          <img
                            src={screen.image}
                            alt={screen.title}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>

      {/* Download CTA */}
      <section className="py-20 bg-gradient-to-r from-primary to-blue-600 text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">Hemen İndirin</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            ExaPet'i ücretsiz indirinn ve evcil hayvanınızın sağlığını profesyonel şekilde takip edin.
          </p>
          <div className="flex justify-center space-x-4">
            <button className="flex items-center space-x-2 bg-black text-white px-6 py-3 rounded-lg font-semibold hover:bg-gray-900 transition-colors">
              <FaApple className="text-2xl" />
              <div className="text-left">
                <div className="text-xs">Download on the</div>
                <div className="text-sm font-bold">App Store</div>
              </div>
            </button>
            <button className="flex items-center space-x-2 bg-black text-white px-6 py-3 rounded-lg font-semibold hover:bg-gray-900 transition-colors">
              <FaGooglePlay className="text-2xl" />
              <div className="text-left">
                <div className="text-xs">GET IT ON</div>
                <div className="text-sm font-bold">Google Play</div>
              </div>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}