import { useState, useEffect } from 'react';
import Menu from '../components/layout/Menu';
import Products from '../components/Products';
import { FaUsers, FaHospital, FaPaw, FaGlobe, FaRocket, FaShieldAlt, FaClock, FaHeadset } from 'react-icons/fa';

export default function Home() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const stats = [
    { icon: <FaUsers className="text-white/90" />, number: "10,000+", label: "Pet Owners" },
    { icon: <FaHospital className="text-white/90" />, number: "500+", label: "Veterinary Clinics" },
    { icon: <FaPaw className="text-white/90" />, number: "50,000+", label: "Pets Registered" },
    { icon: <FaGlobe className="text-white/90" />, number: "25+", label: "Countries" },
  ];

  const features = [
    {
      icon: <FaRocket className="text-primary text-3xl" />,
      title: "Hızlı Entegrasyon",
      description: "Sistemlerinizi hızlıca entegre edin ve hemen kullanmaya başlayın."
    },
    {
      icon: <FaShieldAlt className="text-primary text-3xl" />,
      title: "Güvenli Altyapı",
      description: "En son teknolojilerle güçlendirilmiş güvenli altyapı sistemi."
    },
    {
      icon: <FaClock className="text-primary text-3xl" />,
      title: "7/24 Erişim",
      description: "Verilerinize ve sistemlerinize dilediğiniz an erişin."
    },
    {
      icon: <FaHeadset className="text-primary text-3xl" />,
      title: "Teknik Destek",
      description: "Uzman ekibimizle kesintisiz teknik destek hizmeti."
    }
  ];

  return (
    <div className="min-h-screen">
      <Menu />
      
      {/* Hero Section */}
      <section className="relative bg-gradient-to-r from-dark to-primary pt-32 pb-20 text-white overflow-hidden">
        {/* Animated background pattern */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 opacity-30">
            <div className="absolute inset-0" 
                 style={{
                   backgroundImage: `radial-gradient(circle at 2px 2px, rgba(255,255,255,0.15) 1px, transparent 0)`,
                   backgroundSize: '40px 40px'
                 }}>
            </div>
            <div className="absolute inset-0 rotate-45" 
                 style={{
                   backgroundImage: `radial-gradient(circle at 2px 2px, rgba(255,255,255,0.1) 1px, transparent 0)`,
                   backgroundSize: '30px 30px'
                 }}>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 relative">
          <div className={`grid md:grid-cols-2 gap-12 items-center transition-all duration-1000 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
            <div className="space-y-8">
              <h1 className="text-5xl font-bold leading-tight">
                Dijital Dönüşümün <span className="text-blue-300">Öncüsü</span>
              </h1>
              <p className="text-xl text-gray-200">
                Veteriner sağlık yönetiminde devrim yaratıyoruz. Modern çözümlerle işinizi kolaylaştırıyoruz.
              </p>
              <div className="flex space-x-4">
                <button className="bg-white text-primary px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
                  Hemen Başla
                </button>
                <button className="border-2 border-white px-8 py-3 rounded-lg font-semibold hover:bg-white hover:text-primary transition-colors">
                  Demo İzle
                </button>
              </div>
            </div>
            <div className="hidden md:block transform hover:scale-105 transition-transform duration-500">
              <img 
                src="/hero-image.png" 
                alt="Healthcare Dashboard" 
                className="rounded-lg shadow-2xl"
              />
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="container mx-auto px-4 mt-20">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="text-center transform hover:scale-105 transition-transform duration-300">
                <div className="text-4xl mb-2 flex justify-center">
                  {stat.icon}
                </div>
                <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400">
                  {stat.number}
                </div>
                <div className="text-gray-200">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Products Section */}
      <Products />

      {/* Features Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-4">Neden Exacate?</h2>
          <p className="text-gray-600 text-center max-w-2xl mx-auto mb-12">
            Modern teknolojiler ve yenilikçi çözümlerle işinizi bir üst seviyeye taşıyoruz.
          </p>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="flex justify-center mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold text-center mb-2">{feature.title}</h3>
                <p className="text-gray-600 text-center">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-r from-primary to-blue-600 py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">
            Geleceğe Hazır Mısınız?
          </h2>
          <p className="text-xl text-white/90 mb-8 max-w-2xl mx-auto">
            Dijital dönüşüm yolculuğunuzda yanınızdayız. Hemen ücretsiz demo için başvurun.
          </p>
          <button className="bg-white text-primary px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
            Demo Talep Et
          </button>
        </div>
      </section>
    </div>
  );
} 